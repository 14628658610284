import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { InputAdornment, Button, TextField, LinearProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import styled, { keyframes } from "styled-components";
import { fadeInDown } from 'react-animations';

import Background from '../../components/Background/Background';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';

import * as actions from '../../store/actions/index';

import classes from './Auth.module.css';
import { Person, LockOutlined } from '@material-ui/icons';
// import { Alert, AlertTitle } from '@material-ui/lab';

import logo from '../../assets/images/magniss_logo.png';

const FadeInDownAnimation = keyframes`${fadeInDown}`;
const FadeInDownDiv = styled.div`
  animation: 1 3s ${FadeInDownAnimation};
`;

const Auth = props => {
    const { profiles, defaultProfile, onFetchProfiles, onClearAuthError, onForceLogoutSession } = props;

    const [userName, setUserName] = useState({ value: '', touched: false, isInvalid: false, errorMsg: '' });
    const [password, setPassword] = useState({ value: '', touched: false, isInvalid: false, errorMsg: '' });
    const [profile, setProfile] = useState({ value: '', touched: false, isInvalid: false, errorMsg: '' });

    useEffect(() => {
        onFetchProfiles();
        if (defaultProfile !== '') {
            setProfile({ value: defaultProfile, touched: false, isInvalid: false, errorMsg: '' });
        }
    }, [onFetchProfiles, defaultProfile]);

    const forceLogoutHandler = (errorObj) => {
        onForceLogoutSession(userName.value, profile.value, errorObj.activeToken, errorObj.systemName);
    }

    let errorMessage = null;
    if (props.error) {
        if (props.error.errorCode && props.error.errorCode === '410') {
            errorMessage = (<AlertDialog
                show={props.error !== null}
                onClosed={onClearAuthError}
                title={'Information'}
                message={props.error.message}
                isConfirmDialog={true}
                onConfirmed={forceLogoutHandler.bind(this, props.error)}
            />);
        }
        else {
            errorMessage = (
                // <Alert severity="error" onClose={() => onClearAuthError()}>
                //     <AlertTitle>Error</AlertTitle>
                //     {props.error.message}
                // </Alert>
                <AlertDialog
                    show={props.error !== null}
                    onClosed={onClearAuthError}
                    title={'Information'}
                    message={props.error.message}
                    isConfirmDialog={false}
                />
            );
        }
    }

    let authRedirect = null;
    if (props.isAuthenticated) {
        authRedirect = <Redirect to={props.authRedirectPath} />;
    }

    const userNameChangedHandler = (event) => {
        const currentValue = event.target.value;
        let isInvalid = false;
        let errorMsg = '';
        if (currentValue.length === 0) {
            isInvalid = true;
            errorMsg = 'Username cannot be empty.';
        }
        setUserName({ value: currentValue, touched: true, isInvalid: isInvalid, errorMsg: errorMsg });
    }

    const passwordChangedHandler = (event) => {
        const currentValue = event.target.value;
        let isInvalid = false;
        let errorMsg = '';
        if (currentValue.length === 0) {
            isInvalid = true;
            errorMsg = 'Password cannot be empty.';
        }
        setPassword({ value: currentValue, touched: true, isInvalid: isInvalid, errorMsg: errorMsg });
    }

    const profileChangedHandler = (event, value) => {
        const currentValue = value;
        let isInvalid = false;
        let errorMsg = '';
        if (currentValue.length === 0) {
            isInvalid = true;
            errorMsg = 'Profile cannot be empty.';
        }
        setProfile({ value: currentValue, touched: true, isInvalid: isInvalid, errorMsg: errorMsg });
    }

    const submitHandler = event => {
        event.preventDefault();
        let isValid = true;
        if (userName.value.length === 0) {
            setUserName({ ...userName, isInvalid: true, errorMsg: 'Username cannot be empty.' });
            isValid = false;
        }
        if (password.value.length === 0) {
            setPassword({ ...password, isInvalid: true, errorMsg: 'Password cannot be empty.' });
            isValid = false;
        }
        if (profile.value.length === 0) {
            setProfile({ ...profile, isInvalid: true, errorMsg: 'Profile cannot be empty.' });
            isValid = false;
        }
        if (!isValid) {
            return;
        }
        props.onAuth(userName.value, password.value, profile.value);
        props.onInitiateReportState();
    };

    let profileCombo = (
        <React.Fragment>
            <div className={classes.InputPadding}>
                <Autocomplete
                    id="profile"
                    fullWidth
                    options={profiles !== null ? profiles : []}
                    getOptionSelected={(option, value) => option}
                    renderInput={(params) => <TextField {...params} label='Profile' />}
                    // defaultValue={defaultProfile}
                    value={profile.value !== '' ? profile.value : props.selectedProfile !== null ? props.selectedProfile : null}
                    onChange={profileChangedHandler}
                />
            </div>
        </React.Fragment>);

    let submitButton = (<div className={classes.Button}>
        <Button type='submit' variant='contained' color='primary'>LOGIN</Button>
    </div>);

    const progressBar = (props.loading ? <LinearProgress color="secondary" /> : null);

    return (
        <Background>
            <FadeInDownDiv>
                <div className={classes.Auth}>
                    {authRedirect}
                    <div className={classes.LogoImage}>
                        <img src={logo} alt='App Logo' style={{ width: '100%', height: '100%', paddingBottom: '10px' }} />
                    </div>
                    {errorMessage}
                    {progressBar}
                    <form onSubmit={submitHandler}>
                        <div className={classes.InputPadding}>
                            <TextField fullWidth label='Username...' id='uname' type='text' InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Person className={classes.inputIconsColor} />
                                        </InputAdornment>)
                                }
                            }
                                error={userName.isInvalid}
                                helperText={userName.errorMsg}
                                value={userName.value}
                                onChange={userNameChangedHandler} />
                        </div>
                        <div className={classes.InputPadding}>
                            <TextField fullWidth label='Password' id='upass' type='password' InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockOutlined className={classes.inputIconsColor} />
                                        </InputAdornment>),
                                    autoComplete: "off"
                                }
                            }
                                error={password.isInvalid}
                                helperText={password.errorMsg}
                                value={password.value}
                                onChange={passwordChangedHandler} />
                        </div>
                        {profileCombo}
                        {submitButton}
                    </form>
                </div>
            </FadeInDownDiv>
        </Background>
    );
};

const mapStateToProps = state => {
    return {
        profiles: state.auth.profiles,
        defaultProfile: state.auth.defaultProfile,
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.accessToken !== null,
        authRedirectPath: state.auth.authRedirectPath,
        accessToken: state.auth.accessToken,
        selectedProfile: state.auth.selectedProfile
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchProfiles: () =>
            dispatch(actions.fetchProfiles()),
        onAuth: (userName, password, profile) =>
            dispatch(actions.auth(userName, password, profile)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        onClearAuthError: () => dispatch(actions.clearAuthError()),
        onInitiateReportState: () =>
            dispatch(actions.initiateReportState()),
        onForceLogoutSession: (userName, profile, sessionId, systemName) =>
            dispatch(actions.forceLogoutSession(userName, profile, sessionId, systemName)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth);
