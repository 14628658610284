import axios from '../../axios-instance';

import FileDownload from 'js-file-download';

import * as actionTypes from './actionTypes';
import { ddSelectedRowVals } from '../../shared/utility';

export const fetchDataSourcesStart = () => {
    return {
        type: actionTypes.FETCH_DATASOURCES_START
    };
};

export const fetchDataSourcesSuccess = (dataSources) => {
    return {
        type: actionTypes.FETCH_DATASOURCES_SUCCESS,
        dataSources: dataSources
    };
};

export const fetchDataSourcesFail = (error) => {
    return {
        type: actionTypes.FETCH_DATASOURCES_FAIL,
        error: error
    };
};

export const fetchDataSources = () => {
    return dispatch => {
        dispatch(fetchDataSourcesStart());
        const version = 1;
        const url = `/api/v${version}/report/getdatasources`;
        axios.get(url)
            .then(res => {
                dispatch(fetchDataSourcesSuccess(res.data));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchDataSourcesFail(errMsgData));
            });
    };
};

export const fetchFoldersStart = () => {
    return {
        type: actionTypes.FETCH_FOLDERS_START
    };
};

export const fetchFoldersSuccess = (defaultFolderId, folders) => {
    return {
        type: actionTypes.FETCH_FOLDERS_SUCCESS,
        defaultFolderId: defaultFolderId,
        folders: folders
    };
};

export const fetchFoldersFail = (error) => {
    return {
        type: actionTypes.FETCH_FOLDERS_FAIL,
        error: error
    };
};

export const fetchFolders = () => {
    return dispatch => {
        dispatch(fetchFoldersStart());
        const version = 2;
        const url = `/api/v${version}/report/getfolders`;
        axios.get(url)
            .then(res => {
                dispatch(fetchFoldersSuccess(res.data.defaultFolderId, res.data.folderInfos));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchFoldersFail(errMsgData));
            });
    };
};

export const fetchReportListStart = () => {
    return {
        type: actionTypes.FETCH_REPORT_LIST_START
    };
};

export const fetchReportListSuccess = (reportList) => {
    return {
        type: actionTypes.FETCH_REPORT_LIST_SUCCESS,
        reportList: reportList
    };
};

export const fetchReportListFail = (error) => {
    return {
        type: actionTypes.FETCH_REPORT_LIST_FAIL,
        error: error
    };
};

export const fetchReportList = (sourceId, folderId) => {
    return dispatch => {
        dispatch(fetchReportListStart());
        const version = 1;
        const url = `/api/v${version}/report/getreports`;
        const data = { sourceId: sourceId, folderId: folderId }
        axios.post(url, data)
            .then(res => {
                dispatch(fetchReportListSuccess(res.data));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchReportListFail(errMsgData));
            });
    };
};

export const fetchReportInfoStart = () => {
    return {
        type: actionTypes.FETCH_REPORT_INFO_START
    };
};

export const fetchReportInfoSuccess = (reportKey, reportInfo) => {
    return {
        type: actionTypes.FETCH_REPORT_INFO_SUCCESS,
        reportKey: reportKey,
        reportInfo: reportInfo
    };
};

export const fetchReportInfoFail = (error) => {
    return {
        type: actionTypes.FETCH_REPORT_INFO_FAIL,
        error: error
    };
};

export const fetchReportInfo = (reportGuid, reportId, reportTypeCode, ddEFChildGuid) => {
    return dispatch => {
        dispatch(fetchReportInfoStart());
        const version = 1;
        let url = `/api/v${version}/report/getreportinfo`;
        if (reportTypeCode === 'PG') {
            url = `/api/v${version}/report/getpgreportinfo`;
        }
        else if (reportTypeCode === 'DD') {
            url = `/api/v${version}/report/getddreportinfo`;
        }
        else if (reportTypeCode === 'EF') {
            url = `/api/v${version}/report/get360degreportinfo`;
        }
        const data = { reportId: reportId, reportTypeCode: reportTypeCode, ddEFChildGuid: ddEFChildGuid }
        axios.post(url, data)
            .then(res => {
                dispatch(fetchReportInfoSuccess(reportGuid, res.data));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchReportInfoFail(errMsgData));
            });
    };
};

export const fetchReportDataStart = () => {
    return {
        type: actionTypes.FETCH_REPORT_DATA_START
    };
};

export const fetchReportDataSuccess = (reportKey, tabGuid, resultData) => {
    return {
        type: actionTypes.FETCH_REPORT_DATA_SUCCESS,
        tabGuid: tabGuid,
        reportKey: reportKey,
        resultData: resultData
    };
};

export const fetchReportDataFail = (error) => {
    return {
        type: actionTypes.FETCH_REPORT_DATA_FAIL,
        error: error
    };
};

export const fetchReportData = (reportKey, tabGuid, reportId, reportTypeCode, filterParamInfos) => {
    return dispatch => {
        dispatch(fetchReportDataStart());

        const paramInfosArray = [];
        if(filterParamInfos) {
            Object.keys(filterParamInfos).forEach(key => paramInfosArray.push({
                displayName: key,
                filterValues: filterParamInfos[key].filterValues
            }));
        }

        const version = 1;
        const url = `/api/v${version}/report/getreportlistdata`;
        const data = { reportId: reportId, reportTypeCode: reportTypeCode, filterParamInfos: paramInfosArray };
        axios.post(url, data)
            .then(res => {
                const resultData = res.data;
                dispatch(fetchReportDataSuccess(reportKey, tabGuid, resultData));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchReportDataFail(errMsgData));
            });
    };
};

export const fetchReportFilterDataStart = () => {
    return {
        type: actionTypes.FETCH_REPORT_FILTER_DATA_START
    };
};

export const fetchReportFilterDataSuccess = (reportKey, filterKey, filterData) => {
    return {
        type: actionTypes.FETCH_REPORT_FILTER_DATA_SUCCESS,
        reportKey: reportKey,
        filterKey: filterKey,
        filterData: filterData
    };
};

export const fetchReportFilterDataFail = (error) => {
    return {
        type: actionTypes.FETCH_REPORT_FILTER_DATA_FAIL,
        error: error
    };
};

export const fetchReportFilterData = (reportKey, filterKey, reportId, reportTypeCode, paramDisplayName, filterParamInfos) => {
    return dispatch => {
        dispatch(fetchReportFilterDataStart());
   
        const paramInfosArray = [];
        if(filterParamInfos) {
            Object.keys(filterParamInfos).forEach(key => paramInfosArray.push({
                displayName: key,
                filterValues: filterParamInfos[key].filterValues
            }));
        }

        const version = 1;
        const url = `/api/v${version}/report/getfilterlistdata`;
        const data = {
            reportId: reportId,
            reportTypeCode: reportTypeCode,
            paramDisplayName: paramDisplayName,
            filterParamInfos: paramInfosArray
        };
        axios.post(url, data)
            .then(res => {
                let resultData = res.data;
                dispatch(fetchReportFilterDataSuccess(reportKey, filterKey, resultData));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchReportFilterDataFail(errMsgData));
            });
    };
};

export const changeReportFilterValuesSuccess = (reportKey, filterDisplayName, filterValues) => {
    return {
        type: actionTypes.CHANGE_REPORT_FILTER_VALUES,
        reportKey: reportKey,
        filterDisplayName: filterDisplayName,
        filterValues: filterValues
    };
};

export const changeReportFilterValues = (reportKey, filterDisplayName, filterValues) => {
    return dispatch => {
        dispatch(changeReportFilterValuesSuccess(reportKey, filterDisplayName, filterValues));
    };
};

export const initiateReportStateSuccess = () => {
    return {
        type: actionTypes.INITIATE_REPORT_STATE
    };
};

export const initiateReportState = () => {
    return dispatch => {
        dispatch(initiateReportStateSuccess());
    };
};

export const removeOpenedReportSuccess = (reportKey) => {
    return {
        type: actionTypes.REMOVE_OPENED_REPORT,
        reportKey: reportKey
    };
};

export const removeOpenedReport = (reportKey) => {
    return dispatch => {
        dispatch(removeOpenedReportSuccess(reportKey));
    };
};

export const setActiveTabIndexSuccess = (tabIndex) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB_INDEX,
        activeTabIndex: tabIndex
    };
};

export const setActiveTabIndex = (tabIndex) => {
    return dispatch => {
        dispatch(setActiveTabIndexSuccess(tabIndex));
    };
};

export const setLoadingStatusSuccess = (loading) => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        loading: loading
    };
};

export const setLoadingStatus = (loading) => {
    return dispatch => {
        dispatch(setLoadingStatusSuccess(loading));
    };
};

export const setTabRefreshStatusSuccess = (reportKey, tabRefreshStatus) => {
    return {
        type: actionTypes.SET_TAB_REFRESH_STATUS,
        reportKey: reportKey,
        tabRefreshStatus: tabRefreshStatus
    };
};

export const setTabRefreshStatus = (reportKey, tabRefreshStatus) => {
    return dispatch => {
        dispatch(setTabRefreshStatusSuccess(reportKey, tabRefreshStatus));
    };
};

export const removeOpenedDDLevelSuccess = (reportKey, levelKey) => {
    return {
        type: actionTypes.REMOVE_OPENED_DD_LEVEL,
        reportKey: reportKey,
        levelKey: levelKey
    };
};

export const removeOpenedDDLevel = (reportKey, levelKey) => {
    return dispatch => {
        dispatch(removeOpenedDDLevelSuccess(reportKey, levelKey));
    };
};

export const setActiveDDLevelIndexSuccess = (reportKey, levelIndex) => {
    return {
        type: actionTypes.SET_ACTIVE_DD_LEVEL_INDEX,
        reportKey: reportKey,
        activeLevelIndex: levelIndex
    };
};

export const setActiveDDLevelIndex = (reportKey, levelIndex) => {
    return dispatch => {
        dispatch(setActiveDDLevelIndexSuccess(reportKey, levelIndex));
    };
};

export const fetchDDChildReportInfoStart = () => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_INFO_START
    };
};

export const fetchDDChildReportInfoSuccess = (reportKey, reportInfo, ddEFChildGuid, ddLevelKey, ddLevelVals) => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_INFO_SUCCESS,
        reportKey: reportKey,
        ddLevelKey: ddEFChildGuid,
        reportInfo: reportInfo,
        ddParentKey: ddLevelKey,
        ddParentVals: ddLevelVals
    };
};

export const fetchDDChildReportInfoFail = (error) => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_INFO_FAIL,
        error: error
    };
};

export const fetchDDChildReportInfo = (reportGuid, reportId, reportTypeCode, ddEFChildGuid, ddLevelKey, ddLevelVals) => {
    return dispatch => {
        dispatch(fetchDDChildReportInfoStart());
        const version = 1;
        const url = `/api/v${version}/report/getddreportinfo`;
        const data = { reportId: reportId, reportTypeCode: reportTypeCode, ddEFChildGuid: ddEFChildGuid }
        axios.post(url, data)
            .then(res => {
                dispatch(fetchDDChildReportInfoSuccess(reportGuid, res.data, ddEFChildGuid, ddLevelKey, ddLevelVals));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchDDChildReportInfoFail(errMsgData));
            });
    };
};

export const fetchDDChildReportDataStart = () => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_DATA_START
    };
};

export const fetchDDChildReportDataSuccess = (reportKey, tabGuid, resultData) => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_DATA_SUCCESS,
        tabGuid: tabGuid,
        reportKey: reportKey,
        resultData: resultData
    };
};

export const fetchDDChildReportDataFail = (error) => {
    return {
        type: actionTypes.FETCH_DDCHILD_REPORT_DATA_FAIL,
        error: error
    };
};

export const fetchDDChildReportData = (reportKey, tabGuid, reportId, reportTypeCode, filterParamInfos, parentSelectedVals) => {
    return dispatch => {
        dispatch(fetchDDChildReportDataStart());

        const paramInfosArray = [];
        if(filterParamInfos) {
            Object.keys(filterParamInfos)
                  .forEach(key => paramInfosArray.push({
                        displayName: key,
                        filterValues: filterParamInfos[key].filterValues
                    }));
        }

        const selectedRowInfos = ddSelectedRowVals(parentSelectedVals);

        const version = 1;
        const url = `/api/v${version}/report/getddchildreportlistdata`;
        const data = {
            reportId: reportId,
            reportTypeCode: reportTypeCode,
            ddChildGuid: tabGuid,
            filterParamInfos: paramInfosArray,
            ddSelectedRowInfos: selectedRowInfos
        };
        console.log(data);
        axios.post(url, data)
            .then(res => {
                if(res) {
                    const resultData = res.data;
                    dispatch(fetchDDChildReportDataSuccess(reportKey, tabGuid, resultData));
                }
                else {
                    let errMsgData = {
                        message: 'Invalid response from server. Please contact support.'
                    };
                    dispatch(fetchDDChildReportDataFail(errMsgData));
                }
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchDDChildReportDataFail(errMsgData));
            });
    };
};

export const exportReportDataStart = () => {
    return {
        type: actionTypes.EXPORT_REPORT_DATA_START
    };
};

export const exportReportDataSuccess = (reportKey, tabGuid, exportedFileObject, exportType) => {
    return {
        type: actionTypes.EXPORT_REPORT_DATA_SUCCESS,
        tabGuid: tabGuid,
        reportKey: reportKey,
        exportedFileObject: exportedFileObject,
        exportType: exportType
    };
};

export const exportReportDataFail = (error) => {
    return {
        type: actionTypes.EXPORT_REPORT_DATA_FAIL,
        error: error
    };
};

export const exportReportData = (reportKey, tabGuid, reportId, reportName, reportTypeCode, filterParamInfos, exportType) => {
    return dispatch => {
        dispatch(exportReportDataStart());

        const paramInfosArray = [];
        if(filterParamInfos) {
            Object.keys(filterParamInfos).forEach(key => paramInfosArray.push({
                displayName: key,
                filterValues: filterParamInfos[key].filterValues
            }));
        }

        const version = 1;
        const url = `/api/v${version}/report/getexportedfileobject`;
        const data = {
            reportId: reportId,
            reportName: reportName,
            reportTypeCode: reportTypeCode,
            filterParamInfos: paramInfosArray,
            reportExportType: exportType
        };
        axios.post(url, data, {
            responseType: 'blob',
        })
            .then(res => {
                const filename = reportName.replace(/[/\\?%*:|"<>]/g, '-') + `.${exportType}`;
                FileDownload(res.data, filename);
                dispatch(exportReportDataSuccess(reportKey, tabGuid, res.data, exportType));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(exportReportDataFail(errMsgData));
            });
    };
};

export const clearReportError = () => {
    return {
        type: actionTypes.CLEAR_REPORT_ERROR,
        error: null
    };
};