import React from 'react';

import appLogo from '../../assets/images/magniss_icon.png';
import classes from './Logo.css';

const Logo = (props) => {
    const onLogoClick = () => {
        window.location.href = 'https://www.magniss.com/';
    }

    return (
        <div className={classes.Logo} style={{height: props.height, cursor: 'pointer'}}>
            <img style={{height: '100%', cursor: 'pointer'}} onClick={onLogoClick} src={appLogo} alt="Magniss" />
        </div>
    );
};

export default Logo;