import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { InputLabel, InputAdornment, Button, TextField, LinearProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { VpnKey } from '@material-ui/icons';

import Background from '../../../components/Background/Background';

import * as actions from '../../../store/actions/index';

import classes from './ActivateLicense.module.css';

import logo from '../../../assets/images/magniss_logo.png';

const ActivateLicense = props => {
    const { licenseInfo, onActivateLicenseInfo, onClearAuthError } = props;
    const [licenseKey, setLicenseKey] = useState({ value: '', touched: false, isInvalid: false, errorMsg: '' });

    let errorMessage = null;
    if (props.error) {
        errorMessage = (
            <Alert severity="error" onClose={() => onClearAuthError()}>
                <AlertTitle>Error</AlertTitle>
                {props.error.message}
            </Alert>
        );
    }

    let authRedirect = null;
    if (licenseInfo && licenseInfo.hasValidLicense) {
        authRedirect = <Redirect to='/' />;
    }

    const licenseKeyChangedHandler = (event) => {
        const currentValue = event.target.value;
        let isInvalid = false;
        let errorMsg = '';
        if (currentValue.length === 0) {
            isInvalid = true;
            errorMsg = 'License key cannot be empty.';
        }
        setLicenseKey({ value: currentValue, touched: true, isInvalid: isInvalid, errorMsg: errorMsg });
    }

    const submitHandler = event => {
        event.preventDefault();
        let isValid = true;
        if (licenseKey.value.length === 0) {
            setLicenseKey({ ...licenseKey, isInvalid: true, errorMsg: 'License key cannot be empty.' });
            isValid = false;
        }
        if (!isValid) {
            return;
        }
        onActivateLicenseInfo(licenseKey.value);
    };

    let submitButton = (<div className={classes.Button}>
        <Button type='submit' variant='contained' color='primary'>ACTIVATE</Button>
    </div>);

    const progressBar = (props.loading ? <LinearProgress color="secondary" /> : null);

    return (
        <Background>
            <div className={classes.ActivateLicense}>
                {authRedirect}
                <div className={classes.LogoImage}>
                    <img src={logo} alt='App Logo' style={{ width: '100%', height: '100%', paddingBottom: '10px' }} />
                </div>
                {errorMessage}
                {progressBar}
                <form onSubmit={submitHandler}>
                    <div className={classes.InputPadding}>
                        <InputLabel id='licLabel' color='primary'>
                            To activate your license, enter your activation key in the License Key field below. Note that an internet connection is required for activation.
                        </InputLabel>
                        <TextField fullWidth label='License Key...' id='lickey' type='text' InputProps={
                            {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <VpnKey className={classes.inputIconsColor} />
                                    </InputAdornment>)
                            }
                        }
                            error={licenseKey.isInvalid}
                            helperText={licenseKey.errorMsg}
                            value={licenseKey.value}
                            onChange={licenseKeyChangedHandler} />
                    </div>
                    {submitButton}
                </form>
            </div>
        </Background>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        licenseInfo: state.auth.licenseInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onActivateLicenseInfo: (licenseKey) =>
            dispatch(actions.activateLicenseInfo(licenseKey)),
        onClearAuthError: () => dispatch(actions.clearAuthError()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivateLicense);